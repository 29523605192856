import CloseIcon from "@mui/icons-material/Close";
import { Box, Drawer, IconButton } from "@mui/material";
import Navbar from "./Navbar";

interface MobileDrawerProps {
  open: boolean;
  onClose: () => void;
}

const MobileDrawer = ({ open, onClose }: MobileDrawerProps) => {
  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: 0,
          width: "100%",
          height: "100%",
          position: "relative",
          background: "rgba(255, 255, 255, 1)",
          // backdropFilter: "blur(10px)",
          // boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
          // WebkitBackdropFilter: "blur(5px)",
        },
      }}
    >
      <Box
        role="presentation"
        onClick={onClose}
        onKeyDown={onClose}
        sx={{
          height: "100vh",
          position: "relative",
        }}
      >
        <Navbar />
        <IconButton
          onClick={onClose}
          sx={{
            top: 10,
            right: 5,
            margin: 1.5,
            position: "absolute",
            bgcolor: "background.white",
            "&:hover": {
              bgcolor: "action.hover",
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Drawer>
  );
};

export default MobileDrawer;
