import { IconName } from "../../components/common/CustomIcon";
import { ROUTES_CONFIG } from "../../routes/routesConfig";

interface NavigationElementProps {
  title: string;
  icon: IconName;
  route: string;
  disabled: boolean;
}

export const navigationElements: NavigationElementProps[] = [
  // {
  //   title: "components-navigation-dashboard",
  //   icon: "dashboard",
  //   route: ROUTES_CONFIG.APP.routes.DASHBOARD.path,
  //   disabled: false,
  // },
  {
    title: "components-navigation-vends",
    icon: "vends",
    route: ROUTES_CONFIG.APP.routes.VENDS.path,
    disabled: false,
  },
  {
    title: "components-navigation-refill",
    icon: "refill",
    route: ROUTES_CONFIG.APP.routes.REFILL.path,
    disabled: false,
  },
  {
    title: "components-navigation-profile",
    icon: "profile",
    route: ROUTES_CONFIG.APP.routes.PROFILE.path,
    disabled: false,
  },
  {
    title: "components-navigation-settings",
    icon: "settings",
    route: ROUTES_CONFIG.APP.routes.SETTINGS.path,
    disabled: false,
  },
];

export const topNavigationElements: NavigationElementProps[] = [
  // {
  //   title: "components-navigation-dashboard",
  //   icon: "dashboard",
  //   route: ROUTES_CONFIG.APP.routes.DASHBOARD.path,
  //   disabled: false,
  // },
  {
    title: "components-navigation-products",
    icon: "products",
    route: ROUTES_CONFIG.APP.routes.PRODUCTS.path,
    disabled: false,
  },
  {
    title: "components-navigation-vends",
    icon: "vends",
    route: ROUTES_CONFIG.APP.routes.VENDS.path,
    disabled: false,
  },
  {
    title: "components-navigation-refill",
    icon: "refill",
    route: ROUTES_CONFIG.APP.routes.REFILL.path,
    disabled: false,
  },
  {
    title: "components-navigation-productloss",
    icon: "productloss",
    route: ROUTES_CONFIG.APP.routes.PRODUCTLOSS.path,
    disabled: false,
  },
];

export const bottomNavigationElements: NavigationElementProps[] = [
  {
    title: "components-navigation-profile",
    icon: "profile",
    route: ROUTES_CONFIG.APP.routes.PROFILE.path,
    disabled: false,
  },
  {
    title: "components-navigation-settings",
    icon: "settings",
    route: ROUTES_CONFIG.APP.routes.SETTINGS.path,
    disabled: false,
  },
];
