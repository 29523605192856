import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { PRIMARY } from "../../config/style/theme";
import { useLocales } from "../../hooks/useLocales";

interface PageHelmetProps {
  title?: string;
}

export const PageHelmet = ({ title }: PageHelmetProps) => {
  const { currentLanguage } = useLocales();
  const { t } = useTranslation();
  const baseTitle = "BioBringer";
  const fullTitle = title ? `${t(title)} | ${baseTitle}` : baseTitle;

  return (
    <Helmet>
      <html lang={currentLanguage.value} />
      <title>{fullTitle}</title>
      <meta name="theme-color" content={PRIMARY} />
      <meta
        name="description"
        content="BioBringer - Vending Machine Logistics Admin UI"
      />
    </Helmet>
  );
};
