import { Theme } from "@mui/material";
import { ImportedVendsResponse } from "../api/vends";
import { Location } from "react-router-dom";
import { PRODUCTLOSS_TYPES_FIELDS } from "../config/fields/productLossTypesFields";
import i18next from "i18next";

// USER MANAGEMENT ROLES DISPLAY HELPERS:
export const getRoleColor = (role: string, theme: Theme) => {
  switch (role) {
    case "ADMIN":
      return theme.palette.primary.dark;
    case "USER_MNGR":
      return theme.palette.primary.light;
    default:
      return theme.palette.primary.main;
  }
};

//PRODUCT LOSS TYPE CHIP COLOR:
type PaletteColor =
  | "error"
  | "warning"
  | "info"
  | "success"
  | "primary"
  | "secondary";
type PaletteShade = "main" | "light" | "dark";

export const getLossTypeColor = (role: string, theme: Theme) => {
  const typeOption = PRODUCTLOSS_TYPES_FIELDS.find(
    (option) => option.label === role,
  );
  if (!typeOption) return theme.palette.border.main;

  const [palette, shade] = typeOption.color.split(".") as [
    PaletteColor,
    PaletteShade,
  ];
  return theme.palette[palette][shade];
};
export const createGetRoleDisplayName =
  (t: TranslationFunction) => (role: string) => {
    switch (role) {
      case "ADMIN":
        return t("pages-settings-admin");
      case "USER_MNGR":
        return t("pages-settings-user-mngr");
      default:
        return role;
    }
  };

//VALIDATION TRANSLATION TYPE (GENERIC)
export type TranslationFunction = (key: string, options?: object) => string;

//Format Date Function
export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  return date.toLocaleString("de", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  });
};

//Format Value
export const formatValue = (value: any) => {
  if (typeof value === "number" || !isNaN(Number(value))) {
    return new Intl.NumberFormat(i18next.language, {
      notation: "compact",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(Number(value));
  }
  return value;
};

//VENDS HEADCELLS:
export type SortableKeys = keyof ImportedVendsResponse;

//ColorInvert Function:
export const colorToFilter = (color: string, theme: Theme) => {
  if (color === theme.palette.background.white) {
    return "invert(100%) brightness(100%) contrast(100%)";
  }
  return "";
};

//isActive check for navbar elements:
export const isActive = (route: string, location: Location) => {
  if (route === "/") {
    return location.pathname === "/";
  }
  return location.pathname.startsWith(route);
};

//SORT BY DATE:
export const sortByDate = (list: any[], sortKey: string) =>
  list.sort((a: any, b: any) => {
    const dateA = new Date(a[sortKey]).getTime();
    const dateB = new Date(b[sortKey]).getTime();
    return dateB - dateA;
  });

//format european date:
export const formatToSimpleDate = (dateString: string): string => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString().slice();

  return `${day}.${month}.${year}`;
};

//GET AVATAR INITIALS
export const getInitials = (text: string) => {
  return text
    .split(" ")
    .map((word) => word[0])
    .join("")
    .toUpperCase()
    .slice(0, 2);
};
