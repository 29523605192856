import { ListItemButton } from "@mui/material";
import React from "react";
import { useBreakpoint } from "../../hooks/useBreakpoint";
import CustomIcon from "../common/CustomIcon";
import CustomTooltip from "../common/CustomTooltip";

const TenantSelector: React.FC = () => {
  const { lgDown } = useBreakpoint();

  const listItemStyle = {
    height: "50px",
    width: "100%",
    borderRadius: 1,
    backgroundColor: "transparent",
    color: "primary.dark",
    display: "flex",
    justifyContent: "center",
    gap: 1,
    alignItems: "center",
    padding: 1,
    marginBottom: 2,
    "&:hover": {
      background: "transparent",
    },
  };

  return (
    <CustomTooltip
      title={"BioBringer"}
      disabled={false}
      showtooltip={lgDown ? true : false}
    >
      <ListItemButton component="a" disabled={false} sx={listItemStyle}>
        {lgDown ? (
          <CustomIcon icon="biobringerLogoSmall" />
        ) : (
          <>
            <CustomIcon icon="biobringerLogo" size={100} />
          </>
        )}
      </ListItemButton>
    </CustomTooltip>
  );
};

export default TenantSelector;
