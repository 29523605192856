import {
  BinHalf,
  CalendarMinus,
  GlassFragile,
  MinusCircle,
} from "iconoir-react";

export interface ProductLossTypeOption {
  value: string;
  label: string;
  icon: any;
  color: string;
}

export const PRODUCTLOSS_TYPES_FIELDS: ProductLossTypeOption[] = [
  {
    value: "LOST",
    label: "global-productloss-type-lost",
    icon: MinusCircle,
    color: "error.main",
  },
  {
    value: "SPOILED",
    label: "global-productloss-type-spoiled",
    icon: BinHalf,
    color: "secondary.dark",
  },
  {
    value: "EXPIRED",
    label: "global-productloss-type-expired",
    icon: CalendarMinus,
    color: "warning.main",
  },
  {
    value: "BROKEN",
    label: "global-productloss-type-broken",
    icon: GlassFragile,
    color: "info.main",
  },
];
