import { ListItem, useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useBreakpoint } from "../../hooks/useBreakpoint";
import { isActive } from "../../utils/helpers";

interface CustomListItemProps {
  children: React.ReactNode;
  route: string;
  disabled: boolean;
}

const CustomListItem = ({ children, route, disabled }: CustomListItemProps) => {
  const theme = useTheme();
  const { lgUp, smDown } = useBreakpoint();
  const location = useLocation();
  const active = isActive(route, location);
  const showFullNav = lgUp || smDown;

  return (
    <ListItem
      disablePadding
      sx={{
        marginX: "auto",
        height: {
          xs: 56,
          sm: 40,
          lg: 56,
        },
        width: showFullNav ? "100%" : { xs: 56, sm: 40, lg: 56 },
        padding: 0,
        borderRadius: 1,
        border: "1px solid",
        borderColor: active
          ? theme.palette.primary.main
          : theme.palette.border.main,
        backgroundColor: active
          ? theme.palette.primary.main
          : theme.palette.background.white,
        color: active
          ? theme.palette.primary.contrastText
          : theme.palette.text.primary,
        boxShadow: theme.palette.boxShadow.light,
        "&:hover": {
          borderColor: disabled
            ? theme.palette.border.main
            : theme.palette.primary.main,
        },
        "&:hover:disabled": {
          borderColor: theme.palette.border.main,
        },
      }}
      disabled={disabled}
    >
      {children}
    </ListItem>
  );
};

export default CustomListItem;
