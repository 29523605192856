import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import { useLocation } from "react-router-dom";
import MobileNavbar from "../components/navigation/MobileNavbar";
import Navbar from "../components/navigation/Navbar";
import { shouldHideNavbar } from "../config/navigation/hideMobileNavbarRoutes";
import { DESKTOP_CONTENT_CONTAINER_MAX_WIDTH } from "../config/style/theme";
import { useBreakpoint } from "../hooks/useBreakpoint";

interface DefaultLayoutProps {
  children: React.ReactNode;
}

export function DefaultInAppLayout({ children }: DefaultLayoutProps) {
  const { lgDown, smDown } = useBreakpoint();
  const theme = useTheme();
  const location = useLocation();
  const shouldHideMobileNavbar = shouldHideNavbar(location.pathname);

  const desktopContentWrapperStyle = {
    flexGrow: 1,
    padding: lgDown ? 1 : 1.5,
    paddingBottom: 0,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    background: theme.palette.background.light,
  };

  const desktopContentContainerStyle = {
    margin: "0 auto",
    maxWidth: DESKTOP_CONTENT_CONTAINER_MAX_WIDTH,
    width: "100%",
    overflowY: "hidden",
    flexGrow: 1,
    height: `calc(100vh - ${lgDown ? 1 : 1.5})`,
  };

  const mobileContentWrapperStyle = {
    flexGrow: 1,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    background: theme.palette.background.white,
    // paddingBottom: `${MOBILE_BOTTOM_NAVIGATION_HEIGHT}px`,
  };

  const renderDesktopContent = () => (
    <>
      <Navbar />
      <Box sx={desktopContentWrapperStyle}>
        <Box sx={desktopContentContainerStyle}>{children}</Box>
      </Box>
    </>
  );

  const renderMobileContent = () => (
    <>
      {!shouldHideMobileNavbar && <MobileNavbar />}
      <Box sx={mobileContentWrapperStyle}>
        <Box>{children}</Box>
      </Box>
      {/* {!shouldHideMobileNavbar && <MobileBottomNavigation />} */}
    </>
  );

  return (
    <Box
      component="main"
      sx={{
        display: "flex",
        flexDirection: smDown ? "column" : "row",
        height: smDown ? "auto" : "100dvh",
        overflow: "auto",
      }}
    >
      {smDown ? renderMobileContent() : renderDesktopContent()}
    </Box>
  );
}
