import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { useBreakpoint } from "../hooks/useBreakpoint";
import { Fade } from "@mui/material";

interface DefaultPublicLayoutProps {
  children: React.ReactNode;
}

export function DefaultPublicLayout(props: DefaultPublicLayoutProps) {
  const { smDown } = useBreakpoint();
  const theme = useTheme();

  const publicLayoutStyle = {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: smDown
      ? theme.palette.background.white
      : theme.palette.background.light,
    transform: "translateY(0)",
    transition: "transform 0.3s ease-out",
  };
  return (
    <>
      <Fade in timeout={400}>
        <Box component={"main"} sx={publicLayoutStyle}>
          {props.children}
        </Box>
      </Fade>
    </>
  );
}
