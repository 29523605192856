import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SnackbarProvider } from "notistack";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PageHelmet } from "./components/metadata/PageHelmet";
import theme from "./config/style/theme";
import { AuthProvider } from "./hooks/useAuth";
import { useLocales } from "./hooks/useLocales";
import { DefaultInAppLayout } from "./layout/DefaultInAppLayout";
import { DefaultPublicLayout } from "./layout/DefaultPublicLayout";
import { RoutePath } from "./routes/models/RoutePath";
import { ROUTES_CONFIG } from "./routes/routesConfig";
import { getRoutesAsList } from "./routes/utils/routesUtil";
import { closeSnackbar } from "notistack";
import { IconButton } from "@mui/material";
import { IconoirProvider, Xmark } from "iconoir-react";

function App() {
  const { currentLanguage } = useLocales();
  const queryClient = new QueryClient();

  function renderPublicApp(routeObject: RoutePath) {
    const PageComponent = routeObject.component;
    return (
      <DefaultPublicLayout>
        <PageHelmet title={routeObject.title} />
        <PageComponent />
      </DefaultPublicLayout>
    );
  }

  function renderSecureInApp(routeObject: RoutePath) {
    const PageComponent = routeObject.component;
    return (
      <DefaultInAppLayout>
        <PageHelmet title={routeObject.title} />
        <PageComponent />
      </DefaultInAppLayout>
    );
  }

  function renderApp(routeObject: RoutePath) {
    return (
      <Route
        key={`route-${routeObject.path}`}
        path={routeObject.path}
        element={
          routeObject.isSecure
            ? renderSecureInApp(routeObject)
            : renderPublicApp(routeObject)
        }
      />
    );
  }
  return (
    <>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={currentLanguage.locale}
          >
            <ThemeProvider theme={theme}>
              <SnackbarProvider
                autoHideDuration={2500}
                maxSnack={3}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                action={(snackbarId) => (
                  <IconButton onClick={() => closeSnackbar(snackbarId)}>
                    <IconoirProvider iconProps={{ height: 24, width: 24 }}>
                      <Xmark />
                    </IconoirProvider>
                  </IconButton>
                )}
              >
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                {/*<DefaultLayout>*/}
                <BrowserRouter>
                  {/*<Suspense fallback={<Loader />}>*/}
                  <Routes>
                    {getRoutesAsList(ROUTES_CONFIG)
                      .filter((routeObject) => routeObject.component)
                      .map((routeObject) => renderApp(routeObject))}
                  </Routes>
                  {/*</Suspense>*/}
                </BrowserRouter>
                {/*</DefaultLayout>*/}
              </SnackbarProvider>
            </ThemeProvider>
          </LocalizationProvider>
        </QueryClientProvider>
      </AuthProvider>
    </>
  );
}

export default App;
