import { createTheme, responsiveFontSizes } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface PaletteColor {
    lighter?: string;
  }
  interface SimplePaletteColorOptions {
    lighter?: string;
  }
  interface Palette {
    border: Palette["primary"];
    boxShadow: Palette["primary"];
  }
  interface PaletteOptions {
    border?: PaletteOptions["primary"];
    boxShadow?: PaletteOptions["primary"];
  }
  interface TypeBackground {
    light: string;
    dark: string;
    white: string;
    black: string;
  }
}
// SETUP COLORS
export const PRIMARY = "#97470F";
const PRIMARY_LIGHTER = "#F2EAE0";
const PRIMARY_LIGHT = "#DFC0A5";
const PRIMARY_DARK = "#391800";
const SECONDARY = "#546E0F";
const SECONDARY_LIGHT = "#C1CB86";
const SECONDARY_DARK = "#546E0F";
const INFO = "#457B9D";
const SUCCESS = "#8BAF1A";
const WARNING = "#F4A261";
const ERROR = "#E63946";
const BORDER_GREY = "#E0E0E0";
const BACKGROUND_LIGHT = "#FAF7F4";
const BACKGROUND_DARK = "#2A241F";
const BACKGROUND_WHITE = "#FFFFFF";
const BACKGROUND_BLACK = "#1A1412";
//
//NEUTRAL COLOR SCHEME (GREY/BLACK/WHITE, HIGHLIGHTCOLOR)
// const PRIMARY = "#007AFF";
// const PRIMARY_LIGHTER = "#E6F0FF";
// const PRIMARY_LIGHT = "#66A9FF";
// const PRIMARY_DARK = "#202020";
// const SECONDARY = "#757575";
// const SECONDARY_LIGHT = "#A4A4A4";
// const SECONDARY_DARK = "#494949";
// const INFO = "#0288D1";
// const SUCCESS = "#4CAF50";
// const WARNING = "#FFA000";
// const ERROR = "#FF4d45";
// const BORDER_GREY = "#e0e0e0";
// const BACKGROUND_LIGHT = "#f3f3f3";
// const BACKGROUND_DARK = "#202020";
// const BACKGROUND_WHITE = "#FFFFFF";
// const BACKGROUND_BLACK = "#000000";

export const DRAWER_WIDTH = 650;

export const NAVBAR_WIDE_WIDTH = 240;
export const NAVBAR_SMALL_WIDTH = 64;
export const DESKTOP_CONTAINER_HEADER_HEIGHT = 68;
export const DESKTOP_CONTENT_CONTAINER_MAX_WIDTH = 1200;

export const DIALOG_BORDER_RADIUS = "10px";
export const MOBILE_LAYOUT_CONTENT_PADDING = 1.5;

export const MOBILE_HEADER_HEIGHT = 72;
export const MOBILE_HEADER_FONTSIZE = 24;
export const MOBILE_BOTTOM_NAVIGATION_HEIGHT = 56;

export const BOX_SHADOW_LIGHT = "0 0 10px rgba(0, 0, 0, 0.05)";
export const BOX_SHADOW_MAIN = "0 0 10px rgba(0, 0, 0, 0.1)";
export const BOX_SHADOW_DARK = "0 0 10px rgba(0, 0, 0, 0.2)";

export const ENABLE_ANIMATIONS = true;
export const ANIMATION_TIMINGS = {
  header: 150,
  content: 250,
  contentDelay: 25,
};

let theme = createTheme({
  shape: {
    borderRadius: 10,
  },
  palette: {
    primary: {
      main: PRIMARY,
      light: PRIMARY_LIGHT,
      lighter: PRIMARY_LIGHTER,
      dark: PRIMARY_DARK,
    },
    secondary: {
      main: SECONDARY,
      light: SECONDARY_LIGHT,
      dark: SECONDARY_DARK,
    },
    info: {
      main: INFO,
    },
    success: {
      main: SUCCESS,
    },
    warning: {
      main: WARNING,
    },
    error: {
      main: ERROR,
    },
    text: {
      primary: PRIMARY_DARK,
    },
    border: {
      main: BORDER_GREY,
    },
    boxShadow: {
      main: BOX_SHADOW_MAIN,
      light: BOX_SHADOW_LIGHT,
      dark: BOX_SHADOW_DARK,
    },
    background: {
      light: BACKGROUND_LIGHT,
      dark: BACKGROUND_DARK,
      white: BACKGROUND_WHITE,
      black: BACKGROUND_BLACK,
    },
    action: {},
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  components: {
    MuiLink: {
      defaultProps: {
        color: PRIMARY_DARK,
        underline: "always",
      },
    },
    // BUTTON THEMING
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
          textTransform: "none",
          borderRadius: 10,
          fontWeight: 600,
        },
        sizeLarge: {
          height: 48,
        },
        contained: {
          "&:hover": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          boxShadow: "none",
        },
      },
    },

    // STEPPER theming
    MuiStepper: {
      styleOverrides: {
        root: {
          padding: "24px 0",
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontSize: "0.875rem",
          fontWeight: 500,
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
