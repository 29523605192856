import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { Box, Grid } from "@mui/material";
import { useState } from "react";
import { ReactComponent as BioBringerSVG } from "../../assets/svg/logos/biobringer_logo_large_dark.svg";
import { MOBILE_LAYOUT_CONTENT_PADDING } from "../../config/style/theme";
import { useCustomNavigation } from "../../hooks/useCustomNavigation";
import MobileDrawer from "./MobileDrawer";
import AccountMenu from "./AccountMenu";

function MobileNavbar() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const logoRedirectActive = false;
  const { navigateToDashboard } = useCustomNavigation();

  const handleOpenDrawer = () => {
    setDrawerOpen(true);
  };
  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  return (
    <>
      <AppBar
        elevation={0}
        position="fixed"
        sx={{
          bgcolor: "background.white",
          borderRadius: 0,
        }}
      >
        <Container
          sx={{
            paddingX: MOBILE_LAYOUT_CONTENT_PADDING,
          }}
        >
          <Toolbar disableGutters>
            <Grid container xs={12}>
              <Grid item xs={1} sx={{ placeContent: "center" }}>
                <IconButton
                  size="large"
                  aria-label="open drawer"
                  onClick={handleOpenDrawer}
                  edge="start"
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
              <Grid item xs={10} sx={{ placeContent: "center" }}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    aria-label="home"
                    onClick={navigateToDashboard}
                    disabled={!logoRedirectActive}
                  >
                    <BioBringerSVG
                      style={{
                        width: "100%",
                        height: 40,
                        objectFit: "contain",
                      }}
                    />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item xs={1} sx={{ placeContent: "center" }}>
                {/* <AccountMenu /> */}
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar />
      <MobileDrawer open={drawerOpen} onClose={handleCloseDrawer} />
    </>
  );
}

export default MobileNavbar;
