import { lazy } from "react";
import { path } from "./utils/routesUtil";
const IndexPage = lazy(() => import("../pages/IndexPage"));
const LoginPage = lazy(() => import("../pages/public/LoginPage"));
const VendsPage = lazy(() => import("../pages/inapp/vends/VendsPage"));
const RefillSuccessPage = lazy(
  () => import("../pages/inapp/refill/RefillSucessPage"),
);
const RefillOrderPage = lazy(
  () => import("../pages/inapp/refill/RefillOrderPage"),
);
const CSVUploadPage = lazy(
  () => import("../pages/inapp/vends/VendsUploadPage"),
);
const SettingsPage = lazy(() => import("../pages/inapp/settings/SettingsPage"));
const AddProductPage = lazy(
  () => import("../pages/inapp/products/AddProductPage"),
);
const EditProductPage = lazy(
  () => import("../pages/inapp/products/EditProductPage"),
);
const ProductLossPage = lazy(
  () => import("../pages/inapp/productloss/ProductLossPage"),
);
const ProductsPage = lazy(() => import("../pages/inapp/products/ProductsPage"));
const RefillPage = lazy(() => import("../pages/inapp/refill/RefillPage"));
const AddUserPage = lazy(() => import("../pages/inapp/settings/AddUserPage"));
const EditUserPage = lazy(() => import("../pages/inapp/settings/EditUserPage"));
const ProfilePage = lazy(() => import("../pages/inapp/profile/ProfilePage"));
const UserManagementPage = lazy(
  () => import("../pages/inapp/settings/UserManagementPage"),
);
const AddProductLossPage = lazy(
  () => import("../pages/inapp/productloss/AddProductLossPage"),
);
const EditProductLossPage = lazy(
  () => import("../pages/inapp/productloss/EditProductLossPage"),
);
const DashboardPage = lazy(
  () => import("../pages/inapp/dashboard/DashboardPage"),
);
const PATH_ROOT = "/" as const;

// ======= PUBLIC =======
const PATH_LOGIN = path(PATH_ROOT, "login");

// ======= IN APP =======
const PATH_DASHBOARD = path(PATH_ROOT, "dashboard");

const PATH_REFILL = path(PATH_ROOT, "refill");
const PATH_REFILL_ORDER = path(PATH_REFILL, "/order");
const PATH_REFILL_SUCCESS = path(PATH_REFILL, "/success");

const PATH_VENDS = path(PATH_ROOT, "vends");
const PATH_CSV_UPLOAD = path(PATH_VENDS, "/upload");

const PATH_SETTINGS = path(PATH_ROOT, "settings");

const PATH_USERMANAGEMENT = path(PATH_SETTINGS, "/user-management");
const PATH_ADD_USER = path(PATH_USERMANAGEMENT, "/add");
const PATH_EDIT_USER = path(PATH_USERMANAGEMENT, "/:id/edit");

const PATH_PRODUCTLOSS = path(PATH_ROOT, "product-loss");
const PATH_ADD_PRODUCTLOSS = path(PATH_PRODUCTLOSS, "/add");
const PATH_EDIT_PRODUCTLOSS = path(PATH_PRODUCTLOSS, "/:id/edit");

const PATH_PROFILE = path(PATH_ROOT, "profile");

const PATH_PRODUCTS = path(PATH_ROOT, "products");
const PATH_ADD_PRODUCT = path(PATH_PRODUCTS, "/add");
const PATH_EDIT_PRODUCT = path(PATH_PRODUCTS, "/:id/edit");

export const ROUTES_CONFIG = {
  APP: {
    path: PATH_ROOT,
    component: IndexPage,
    title: "pages-root-title",
    routes: {
      LOGIN: {
        path: PATH_LOGIN,
        component: LoginPage,
        title: "pages-login-title",
      },
      DASHBOARD: {
        path: PATH_DASHBOARD,
        isSecure: true,
        component: DashboardPage,
        title: "pages-dashboard-title",
      },
      SETTINGS: {
        path: PATH_SETTINGS,
        isSecure: true,
        component: SettingsPage,
        title: "pages-settings-title",
        routes: {
          USERMANAGEMENT: {
            path: PATH_USERMANAGEMENT,
            isSecure: true,
            component: UserManagementPage,
            title: "pages-settings-manage-users",
            routes: {
              ADD_USER: {
                path: PATH_ADD_USER,
                isSecure: true,
                component: AddUserPage,
                title: "pages-adduser-title",
              },
              EDIT_USER: {
                path: PATH_EDIT_USER,
                isSecure: true,
                component: EditUserPage,
                title: "pages-edituser-title",
              },
            },
          },
        },
      },
      REFILL: {
        path: PATH_REFILL,
        isSecure: true,
        component: RefillPage,
        title: "pages-refill-title",
        routes: {
          ORDER: {
            path: PATH_REFILL_ORDER,
            isSecure: true,
            component: RefillOrderPage,
            title: "pages-refill-order-title",
          },
          SUCCESS: {
            path: PATH_REFILL_SUCCESS,
            isSecure: true,
            component: RefillSuccessPage,
            title: "pages-refill-success-title",
          },
        },
      },
      VENDS: {
        path: PATH_VENDS,
        isSecure: true,
        component: VendsPage,
        title: "pages-vends-title",
        routes: {
          CSV_UPLOAD: {
            path: PATH_CSV_UPLOAD,
            isSecure: true,
            component: CSVUploadPage,
            title: "pages-vends-upload-title",
          },
        },
      },
      PRODUCTS: {
        path: PATH_PRODUCTS,
        isSecure: true,
        component: ProductsPage,
        title: "pages-products-title",
        routes: {
          ADD_PRODUCT: {
            path: PATH_ADD_PRODUCT,
            isSecure: true,
            component: AddProductPage,
            title: "pages-add-product-title",
          },
          EDIT_PRODUCT: {
            path: PATH_EDIT_PRODUCT,
            isSecure: true,
            component: EditProductPage,
            title: "pages-add-product-title",
          },
        },
      },
      PRODUCTLOSS: {
        path: PATH_PRODUCTLOSS,
        isSecure: true,
        component: ProductLossPage,
        title: "pages-productloss-title",
        routes: {
          ADD_PRODUCTLOSS: {
            path: PATH_ADD_PRODUCTLOSS,
            isSecure: true,
            component: AddProductLossPage,
            title: "pages-productloss-add-title",
          },
          EDIT_PRODUCTLOSS: {
            path: PATH_EDIT_PRODUCTLOSS,
            isSecure: true,
            component: EditProductLossPage,
            title: "pages-productloss-edit-loss-title",
          },
        },
      },
      PROFILE: {
        path: PATH_PROFILE,
        isSecure: true,
        component: ProfilePage,
        title: "pages-profile-title",
      },
    },
  },
};
