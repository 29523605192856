import { Box, SxProps, Theme } from "@mui/material";
import React from "react";
import biobringerLogo from "../../assets/png/logos/biobringer_logo_large_dark.png";
import biobringerLogoSmall from "../../assets/png/logos/biobringer_logo_small_dark.png";
import homeIcon from "../../assets/svg/icons/home_icon.svg";
import dashboardIcon from "../../assets/svg/icons/dashboard_icon.svg";
import logoutIcon from "../../assets/svg/icons/logout_icon.svg";
import productsIcon from "../../assets/svg/icons/products_icon.svg";
import profileIcon from "../../assets/svg/icons/profile_icon.svg";
import refillIcon from "../../assets/svg/icons/refill_icon.svg";
import settingsIcon from "../../assets/svg/icons/settings_icon.svg";
import vendsIcon from "../../assets/svg/icons/vends_icon.svg";
import atSignIcon from "../../assets/svg/icons/at_sign_icon.svg";
import phoneIcon from "../../assets/svg/icons/phone_icon.svg";
import suitcaseIcon from "../../assets/svg/icons/suitcase_icon.svg";
import productlossIcon from "../../assets/svg/icons/productloss_icon.svg";
import refillOrderSuccess from "../../assets/svg/illustrations/refill-order-success-illu.svg";
import noDataUploadInfoIllu from "../../assets/svg/illustrations/vends-upload-csv-illu.svg";
import noEntryInfoIllu from "../../assets/svg/illustrations/productloss-illu.svg";

import addProductIllu from "../../assets/svg/illustrations/add-product-illu.svg";
import theme from "../../config/style/theme";
import { colorToFilter } from "../../utils/helpers";

export type IconName =
  | "biobringerLogo"
  | "biobringerLogoSmall"
  | "dashboard"
  | "home"
  | "settings"
  | "refill"
  | "products"
  | "profile"
  | "vends"
  | "productloss"
  | "noEntryInfoIllu"
  | "logout"
  | "phone"
  | "supplier"
  | "refillOrderSuccess"
  | "noDataUploadInfoIllu"
  | "addProductIllu"
  | "email";

const iconMap: Record<IconName, string> = {
  biobringerLogo,
  biobringerLogoSmall,
  dashboard: dashboardIcon,
  home: homeIcon,
  profile: profileIcon,
  products: productsIcon,
  settings: settingsIcon,
  refill: refillIcon,
  vends: vendsIcon,
  noDataUploadInfoIllu,
  addProductIllu,
  productloss: productlossIcon,
  noEntryInfoIllu,
  logout: logoutIcon,
  email: atSignIcon,
  phone: phoneIcon,
  supplier: suitcaseIcon,
  refillOrderSuccess,
};

interface CustomIconProps {
  icon: IconName;
  size?: number;
  color?: string;
  isActive?: boolean;
  sx?: SxProps<Theme>;
}

const CustomIcon: React.FC<CustomIconProps> = ({
  icon,
  size = 24,
  color = "currentColor",
  isActive,
  sx = {},
}) => {
  return (
    <Box
      sx={{
        width: `${size}px`,
        height: `${size}px`,
        display: "inline-block",
        backgroundImage: `url(${iconMap[icon]})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        filter: isActive
          ? colorToFilter(theme.palette.background.white, theme)
          : colorToFilter(color, theme),
        ...sx,
        color: { color },
      }}
    />
  );
};

export default CustomIcon;
