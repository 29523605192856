import { ListItemButton, Typography, useTheme } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import { useBreakpoint } from "../../hooks/useBreakpoint";
import { isActive } from "../../utils/helpers";
import CustomIcon, { IconName } from "../common/CustomIcon";

interface CustomListItemButtonProps {
  title: string;
  icon: IconName;
  route: string;
  disabled: boolean;
  onClick: React.MouseEventHandler;
}

const CustomListItemButton = ({
  title,
  icon,
  route,
  disabled,
  onClick,
}: CustomListItemButtonProps) => {
  const { lgUp, smDown } = useBreakpoint();
  const location = useLocation();
  const active = isActive(route, location);
  const theme = useTheme();

  const showFullNav = lgUp || smDown;
  const customListItemButtonStyle = {
    borderRadius: 1,
    gap: 2,
    p: showFullNav ? "auto" : 0,
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: showFullNav ? "flex-start" : "center",
    alignItems: "center",
  };

  const iconColor = active
    ? theme.palette.primary.contrastText
    : theme.palette.text.primary;
  return (
    <ListItemButton
      component="a"
      href={route}
      onClick={onClick}
      sx={customListItemButtonStyle}
      disabled={disabled}
    >
      <CustomIcon icon={icon} sx={{ color: iconColor }} isActive={active} />
      {showFullNav && (
        <Typography variant="button" fontSize={13.5} fontWeight="bold">
          {title}
        </Typography>
      )}
    </ListItemButton>
  );
};

export default CustomListItemButton;
