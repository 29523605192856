import { useMediaQuery, useTheme } from "@mui/material";

export function useBreakpoint() {
  const theme = useTheme();

  const xsUp = useMediaQuery(theme.breakpoints.up("xs"));
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
  const xsOnly = useMediaQuery(theme.breakpoints.only("xs"));
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const smOnly = useMediaQuery(theme.breakpoints.only("sm"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));

  return {
    xsUp,
    xsDown,
    xsOnly,
    smOnly,
    smUp,
    smDown,
    mdDown,
    mdUp,
    lgDown,
    lgUp,
  };
}
