import { startTransition } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES_CONFIG } from "../routes/routesConfig";

export const useCustomNavigation = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const navigateToLogin = () => {
    startTransition(() => {
      navigate(ROUTES_CONFIG.APP.routes.LOGIN.path);
    });
  };
  const navigateToProducts = () => {
    startTransition(() => {
      navigate(ROUTES_CONFIG.APP.routes.PRODUCTS.path);
    });
  };

  const navigateToAddProduct = () => {
    startTransition(() => {
      navigate(ROUTES_CONFIG.APP.routes.PRODUCTS.routes.ADD_PRODUCT.path);
    });
  };

  const navigateToEditProduct = (productId: string) => {
    startTransition(() => {
      navigate(`/products/${productId}/edit`);
    });
  };
  const navigateToProductLoss = () => {
    startTransition(() => {
      navigate(ROUTES_CONFIG.APP.routes.PRODUCTLOSS.path);
    });
  };

  const navigateToAddProductLoss = () => {
    startTransition(() => {
      navigate(
        ROUTES_CONFIG.APP.routes.PRODUCTLOSS.routes.ADD_PRODUCTLOSS.path,
      );
    });
  };
  const navigateToEditProductLoss = (productlossId: string) => {
    startTransition(() => {
      navigate(`/product-loss/${productlossId}/edit`);
    });
  };
  const navigateToDashboard = () => {
    startTransition(() => {
      navigate(ROUTES_CONFIG.APP.routes.DASHBOARD.path);
    });
  };
  const navigateToUserManagement = () => {
    startTransition(() => {
      navigate(ROUTES_CONFIG.APP.routes.SETTINGS.routes.USERMANAGEMENT.path);
    });
  };

  const navigateToAddUser = () => {
    startTransition(() => {
      navigate(
        ROUTES_CONFIG.APP.routes.SETTINGS.routes.USERMANAGEMENT.routes.ADD_USER
          .path,
      );
    });
  };

  const navigateToEditUser = (userId: string) => {
    startTransition(() => {
      navigate(`/settings/user-management/${userId}/edit`);
    });
  };
  const navigateToRefill = () => {
    startTransition(() => {
      navigate(ROUTES_CONFIG.APP.routes.REFILL.path);
    });
  };

  const navigateToRefillOrder = () => {
    startTransition(() => {
      navigate(ROUTES_CONFIG.APP.routes.REFILL.routes.ORDER.path);
    });
  };
  const navigateToRefillSuccess = () => {
    startTransition(() => {
      navigate(ROUTES_CONFIG.APP.routes.REFILL.routes.SUCCESS.path);
    });
  };
  const navigateToVends = () => {
    startTransition(() => {
      navigate(ROUTES_CONFIG.APP.routes.VENDS.path);
    });
  };
  const navigateToCSVUpload = () => {
    startTransition(() => {
      navigate(ROUTES_CONFIG.APP.routes.VENDS.routes.CSV_UPLOAD.path);
    });
  };
  const navigateToSettings = () => {
    startTransition(() => {
      navigate(ROUTES_CONFIG.APP.routes.SETTINGS.path);
    });
  };

  return {
    goBack,
    navigateToLogin,
    navigateToDashboard,
    navigateToProducts,
    navigateToAddProduct,
    navigateToEditProduct,
    navigateToProductLoss,
    navigateToAddProductLoss,
    navigateToEditProductLoss,
    navigateToAddUser,
    navigateToEditUser,
    navigateToVends,
    navigateToCSVUpload,
    navigateToSettings,
    navigateToUserManagement,
    navigateToRefill,
    navigateToRefillOrder,
    navigateToRefillSuccess,
  };
};
